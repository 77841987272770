/* global jQuery, google, ScrollMagic */

const slickDefaults = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000
}

jQuery(function ($) {
  // add load class to body
  $('body').addClass('loaded')

  // add down arrows on page heros
  if ($('#page-hero').length > 0 && $(window).width() > 768) {
    $('#page-hero').append('<i class="fa fa-angle-down look-down"></i>')
    $('.look-down').animate({
      opacity: 1
    }, 1000)
  }

  // slick home page products
  $('#products .products-container').slick({
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  })

  var count = 1
  $('.home #features img').each(function () {
    $(this).css({
      'animation-delay': count + 's'
    })
    count += .5
  })

  // slick single product page images
  $('.single-products .product-img-container').slick(slickDefaults)

  // slick about page testimonials
  $('#testimonials .testimonials-container').slick({
    dots: true,
    autoplay: false
  })

  $('.about .banner-photo').parallax({
    imageSrc: '/wp-content/themes/jona/dist/img/j-bg-4.jpg',
    zIndex: 2
  })

  // add arrows on team photos
  if ($('#team').length > 0 && $(window).width() > 768) {
    $('#team .member').prepend('<i class="fa fa-caret-down"></i>')
  }

  // add arrows on product photos
  if ($('#products').length > 0 && $(window).width() > 768) {
    $('#products .product').append('<i class="fa fa-caret-down"></i>')
  }

  // ScrollMagic
  var controller = new ScrollMagic.Controller()

  // about page timeline
  $('#timeline .happening').each(function () {
    new ScrollMagic.Scene({
      triggerElement: this.children[0],
      triggerHook: 0.5,
      duration: $(this).outerHeight().toString()
    })
    .setClassToggle(this, 'active')
    .addTo(controller)
  })

  // products
  $('#products .product').each(function () {
    new ScrollMagic.Scene({
      triggerElement: this.children[0],
      triggerHook: 0.5,
      duration: $(this).outerHeight().toString()
    })
    .setClassToggle(this, 'active')
    .addTo(controller)
  })

  // buttons
  $('.btn-std:not(.no), input[type="submit"]').each(function () {
    $(this).css({
      'opacity': '0',
      'transform': 'translateY(30px)'
    })
    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.9,
      duration: '95%'
    })
    .setClassToggle(this, 'fadeInUp')
    .addTo(controller)
  })
})

function initMap () {
  var office = {lat: 49.858372, lng: -119.490940}
  var center
  var zoom = 15
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: zoom,
    center: office,
    scrollwheel: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}]
  })
  var image = {
    url: '/wp-content/themes/jona/dist/img/icon-map-marker.png',
    size: new google.maps.Size(49, 85),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(17, 42),
    scaledSize: new google.maps.Size(25, 42)
  }
  var marker = new google.maps.Marker({
    position: office,
    map: map,
    icon: image
  })
}
